


























import { Component, Prop, Vue, Provide } from "vue-property-decorator";
import { forgotPassword } from '@/apis/password.api';
import { errorPopupHandler, errorCatchHandler, loadings } from '../../../utils/decorators/VueTimmer';

@Component
export default class ForgotPasswordPage extends Vue {
  
  @Provide()
  form={
    email:"",
  }

  loadings = {
    submit: false,
  }

  errors = {
    forgot: "",
  }


  @errorCatchHandler("errors.forgot", "Wrong email or wrong capcha","form")
  @loadings("loadings.submit")
  onSubmit(){
    return forgotPassword(this.form)
    .then(() => this.$router.replace({
      name:"forgot_password_confirm", 
      query:{email: JSON.stringify(this.form.email)}
    }))
  }

  onReset(){
    
  }
}
